<template>
  <div class="relative">
    <transition name="fade">
      <ButtonCarousel
        v-if="!isMobileDevice && showPrevArrow"
        direction="left"
        class="-translate-x-2"
        @click.prevent.stop="moveCarousel(false)"
      />
    </transition>

    <transition name="fade">
      <ButtonCarousel
        v-if="!isMobileDevice && showNextArrow"
        direction="right"
        class="translate-x-2"
        @click.prevent.stop="moveCarousel(true)"
      />
    </transition>

    <div
      ref="ScrollerContainer"
      v-dragscroll="!isMobileDevice"
      class="w-full flex gap-2 flex-nowrap hardware-accelerate relative overscroll-x-contain"
      :class="[
        isMobileDevice ? 'overflow-x-scroll scrollbar-mobile' : 'overflow-x-scroll scrollbar-hide',
        dragging ? '!cursor-grabbing' : '',
        snapping && !dragging ? 'snap-x' : ''
      ]"
      @scroll="handleScroll"
      @resize="handleResize"
      @dragscrollstart="positionStart"
      @dragscrollend="positionEnd"
      @dragscrollmove="dragging = true"
    >
      <slot/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useDeviceStore } from '@/store/device';

const props = defineProps({
  itemsLength: {
    type: Number,
    required: true,
  },
  snapping: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(['dragging',]);

const deviceStore = useDeviceStore();
const {
  isMobileDevice,
} = storeToRefs(deviceStore);

const scrollerPosition = ref(0);
const scrollerWidth = ref(0);
const scrollerContainerWidth = ref(0);
const showNextArrow = ref(true);
const dragging = ref(false);
const oldScrollerPosition = ref(0);
const ScrollerContainer = ref();

const showPrevArrow = computed(() => scrollerPosition.value > 10);

function handleScroll() {
  if (!ScrollerContainer.value) { return; }

  const scrollerPositionNew = ScrollerContainer.value.scrollLeft || 0;

  scrollerPosition.value = scrollerPositionNew;
  showNextArrow.value = scrollerWidth.value - scrollerPositionNew > scrollerContainerWidth.value;
}

function handleResize() {
  scrollerContainerWidth.value = ScrollerContainer.value?.clientWidth;
  scrollerWidth.value = ScrollerContainer.value?.scrollWidth;
  handleScroll();
}

function moveCarousel(next) {
  handleResize();
  const distance = Math.round(scrollerWidth.value / props.itemsLength) * 2;
  let pos = scrollerPosition.value;
  if (next) {
    pos = pos + distance;
  } else {
    pos = pos - distance;
  }

  scrollToPosition(pos);
}

function scrollToPosition(pos) {
  ScrollerContainer.value.scrollTo({
    left: pos,
    behavior: 'smooth',
  });
}

function positionStart() {
  oldScrollerPosition.value = scrollerPosition.value;
}

function positionEnd() {
  const cardsCount = scrollerWidth.value / props.itemsLength;
  const itemsFromLeft = Math.round(scrollerPosition.value / cardsCount);

  const cardWidth = Math.round(cardsCount * 10) / 10;
  let offset = itemsFromLeft * cardWidth;

  // Amend offset for situations when scroll was too subtle for css snap to trigger
  const diff = scrollerPosition.value - oldScrollerPosition.value;
  if (diff < cardWidth / 2) {
    offset += (cardWidth * Math.sign(diff));
  }

  scrollToPosition(offset);

  setTimeout(() => {
    dragging.value = false;
  }, 200);
}

watch(
  () => dragging.value,
  (newVal) => {
    emits('dragging', newVal);
  }
);

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
